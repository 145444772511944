
.img-sup-magazine{
    background-image: url("imagenes/imgfondo.png");
}
#menumagazine{
display: block;
}
#menumagazineresponsiveM{
display: none;
}
.flipbook{
padding-bottom: 4%;
}

.magazine-book{
    display: block;
}

.magazine-pdf{
    display: none;
}

@media screen and (max-width : 575px) { 
    .magazine-book{
        display: none;
    }    


    .magazine-pdf{
        position: relative;
        top: 20px;
        padding-top: 10%;
        display: block;
    }
    .carousel-imgs{
        height: 100%;
    }
    .carousel .control-dots {
        position: absolute;
        bottom: 0;
        margin: 10px 0;
        padding: 0;
        text-align: center;
        width: 100%;
        z-index: revert;
        display: none;
    }

    #contacto{
        padding: 0%;
    }
    #menumagazine{
        display: none;
    }
    #menumagazineresponsiveM{
        display: block;
    }

    .carousel .thumbs {
        transition: all .15s ease-in;
        transform: translate3d(0, 0, 0);
        position: relative;
        list-style: none;
        white-space: nowrap;
        display: none;
    }

 }
