@font-face{
  font-family: Athelas;
  src: url("fonts/Athelas.ttc");
}

html {
  overflow-x: hidden;
 }

.back-color-id{
  background-color: rgb(86, 106, 167);
}
.back-color-id p{
  color: #fff;
}
.back-color-description{
background-color: rgb(177, 131, 221);
}
.back-color-description p{
color: #fff;
}
.back-color-order{
background-color: rgb(212, 186, 153);
}
.back-color-order p{
color: #fff;
}

a {
  color: black;
}
.seccion-1{
  background-image: url("imagenes/imgfondo.png");
  max-width: 100%;
  padding: 15px 20px 60px 20px;
  background-repeat: no-repeat;
  border-bottom: 1px solid rgb(97, 93, 93);
}

.carousel-control-next-icon {
  position: relative;
    left: 80%;
    background-color: black;
}

.visually-hidden{
  display: none;
}


.carousel-control-prev-icon {
    position: relative;
    right: 80%;
    background-color: black;
}

.navbar-expand-lg .navbar-collapse {
  flex-basis: 0;
}
.icons-redes{
  padding-bottom: 70px;
}
 
#contmenu{
  padding: 0px 0px 40px 0px;
  margin-top: 0px;
  font-size: 160%;
}

.menu{
  color: #fff;
/*  padding: 0px 30px 0px 90px;*/
  font-family: 90% "Roboto";
  font-weight: bold;
  /*padding: 0px 0px 15px 0px;*/
}

.navbar-light .navbar-nav .nav-link.active .navbar-light .navbar-nav .nav-link {
  color: white;
}
.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
  color: white;
}

.carousel-indicators{
  display: none;
}

.carousel-caption{
  background: #fff;
  color: #222222;
  position: absolute;
  top:73%;
  border-radius: 6px;
  font-family: "Athelas";
  font-size: 168%;
  max-width: 80%;
  height: 247px;
}
.carousel-flechas .carousel-control-prev-icon {
  background-image: url("./icons/flecha-izquierda.png");

  background-color: white;
  font-size: 50%;
  width: 30%;
  height: 10%;
}
.carousel-flechas .carousel-control-prev-icon {
  position: relative;
  right: 80%;
  background-color: transparent;
  display: block;
  top: 4.2%;
}

.carousel-flechas .carousel-control-next-icon {
  background-image: url("./icons/flecha-derecha.png");
  width: 30%;
  height: 10%;
}

.carousel-flechas .carousel-control-next-icon {
  position: relative;
  left: 80%;
  background-color: transparent;
  display: block;
  top: 4.2%;
}

.carousel-control-prev-icon {
  display: none;
}

.carousel-control-next-icon {
  display: none;
}

.InfiniteCarouselArrowIcon {
  display: inline-block;
  padding: 10px;
  border: solid rgb(224, 6, 159);
  border-width: 0 5px 5px 0;
}

.box-estados{
  font-size: 14px;
  font-family: "Roboto";
  border: 2px solid black;
  max-width: 70px;
  max-height: 25px;
  align-items: center;
  margin: 0px 0px 0px 100px;
  border-radius: 2px;
  display:flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0px 0px 0px;
  border: 2px solid rgb(12, 12, 12);
  position: relative;
  left: 30%;
}
.cont-estados{
  height: 200%;
}

.cont-img-estados{
    padding: 0% 8% 0% 8%;
}


.seccion-2{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 27px 0px 42px 0px;
  border-bottom: 1px solid rgb(97, 93, 93);
  max-width: 100%;
}

.cont-seccion-2{
  
}
/* haci estaba
  .cont-seccion-2{
  padding-left: 10%;
  padding-right: 10%;
}
  */

.seccion-3{
padding-top: 40px;
padding-bottom: 60px;
}

.box-locales{
  font-size: 22px;
  color: rgb(231, 20, 161);
  font-family: "Roboto";
  border: 1px solid black;
  max-width: 200px;
  max-height: 25px;
  align-items: center;
  margin: 0px 0px 0px 40%;
  border-radius: 2px;
  display:flex;
  justify-content: center;
  align-items: center;
  padding: 1.4% 0px 0px 0px;
  position:relative;
  left: 1%;
  bottom: 54px;
  background-color: #fff;
}
.box-locales-responsive{
  display: none;
}

.date{
  font-family: "Roboto";
  color: #222222;
  font-size: 14px;
  font-weight: bold;
}
.text-carrousel-locales{
  font-family: "Athelas";
  font-weight: bold;
  font-size: 22px;
  color: #222222;
}
.cont-player{
  background-color: #313030;
  max-width: 100%;
  max-height: 100%;
  padding-top: 1%;
  padding-bottom: 1%;
}

.contvideoimg{
  margin-bottom: -8%;
}
.cont-player-responsive{
  display: none;
}

.cont-player-2{
  position: relative;
  left: 10%;
}
.cont-player-3{
  position: relative;
  width: 116%;
  height: 100%;
  right: 19%
}
.box-picture{
  width: 30%;
  max-height: 200%;
  padding-top: 30px;
  padding-bottom: 30px;
}
.box-box-player-picture{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 0%;
}
#mundo{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 0px 0px 0px;
  border-top: 1px solid rgb(97, 93, 93);
  max-width: 100%; 
}

.box-estasemana{
  font-size: 22px;
  color: rgb(231, 20, 161);
  font-family: "Roboto";
  border: 1px solid black;
  max-width: 200px;
  max-height: 25px;
  align-items: center;
  margin: 0px 0px 0px 40%;
  border-radius: 2px;
  display:flex;
  justify-content: center;
  align-items: center;
  padding: 1.9% 0px 0px 0px;
  position:relative;
  left: 19%;
  bottom: 13px;
  z-index: 999;
  background-color: #fff;
}
.cont-banders{
  max-width:340px;
  background-color: #f8f8f8;
  padding-top: 8%;
}

.card-body{
  max-width: 512px;
  max-height: 100%;
  padding-top: 20px;
  padding-bottom: 25px;
}

.InfiniteCarouselArrowPrev {
  left: auto;
  right: 1120px;
}

.InfiniteCarouselArrowNext {
  left: 1120px;
  right: auto;
}
.cont-notes{
  padding-top: 5%;
  display: flex;
  flex-wrap: nowrap;
}

#cont-notes-bottom{
  padding-bottom: 5%;
}

.box-seccion-month{
  max-width: 25%;
  height: 10%;
  border: 2px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  margin-bottom: 3%;
}

.box-seccion-month p{
  margin: 0px;
  padding-right: 13%;
}

.card-title{
  font-family: "Athelas";
  font-size: 24px;
  color: #000;
  padding-right: 105px;
}

.card-text{
  font-family: "Roboto";
  font-size: 18px;
  color: #000;
  padding-right: 100px;
}

.cont-bader-2{
  padding-top: 25%;
  height: 50%;
}
.anuncio-baninf1{
  background-color: #fceeee;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5% 0px 1.5% 0px;
  max-width: 100%;
  margin-top: 1%;
}
.cont-anuncio-baninf1 {
  
  aspect-ratio: auto;
}

.cont-interesante li{
  color: #222222;
  font-family: "Roboto" "Bold";
  font-size: 22px;
}

.cont-interesante{
  padding: 45px 0px 60px 0px;
  border-top: 1px solid rgb(97, 93, 93);
  border-bottom: 1px solid rgb(97, 93, 93);
  max-width: 100%;
  margin-top: 5%;
  display: block;
}

.div-interesante-1, .div-interesante-2{
max-width: 66%;
}
.div-interesante-1 ul li{
  padding-top: 4%;
}

.div-interesante-2 ul li{
  padding-top: 4%;
}
.div-interesante-1{
  margin-left: 36%;
}
.box-interesante{
  font-size: 22px;
  color: rgb(231, 20, 161);
  font-family: "Roboto";
  border: 1px solid black;
  max-width: 276px;
  max-height: 25px;
  align-items: center;
  margin: 0px 0px 0px 40%;
  border-radius: 2px;
  display:flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0px 0px 0px;
  position:relative;
  left: 4%;
  bottom: 58px;
  z-index: 999;
  background-color: #fff;
}

.contactos{
  width: 10%;
  height: 10%;
}
.box-icons{
  width: 10%;
}
.box-principal-icons p{
  margin-bottom: 0%;
}

.icontel ul li ::marker {
  list-style-image: url("./imagenes/IcoTelefono.svg");
}

.cont-revistasate{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.cont-baninf2{
  display: flex;
  align-items: center;
  justify-content: center;
}
.cont-baninf2-cont{
  width: 90%;
  padding-left: 7%;
}
.cont-contacto{
  display: flex;
  align-items: center;
  justify-content: center
}
.text-anuncio-nosotros p{
  font-family: "Roboto";
  font-size: 22px;
  color: #222222;
}

.img-sate{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2%;
}

.text-sate{
  font-family: "Roboto" "Black";
  font-size: 22px;
  color: #222222;
}
.text-sub-sate{
  font-family: "Roboto" "Bold";
  font-size: 19px;
  color: #222222;
}
.cont-tel{
  padding-left: 13%;
  padding-bottom: 15%;
  font-family: "Roboto" "Bold";
  font-size: 19px;
  color: #222222;
}
.cont-contacto .cont-textosup-icons p{
  margin-bottom: 0.5%;
}
.cont-textosup-icons{
  font-family: "Roboto";
  font-size: 20px;
  color: #222222;
}
.ico-facebook{
 padding-left: 10%;
}
.seccion-5{
  padding: 45px 0px 60px 0px;
}
.box-contacto{
 font-size: 22px;
 color: rgb(231, 20, 161);
  font-family: "Roboto";
  border: 1px solid black;
  max-width: 200px;
  max-height: 25px;
  align-items: center;
  margin: 0px 0px 0px 40%;
  border-radius: 2px;
  display:flex;
  justify-content: center;
  align-items: center;
  padding: 1.5% 0px 0px 0px;
  position:relative;
  left: 4%;
  bottom: 57px;
  z-index: 999;
  background-color: #fff;
}

.cont-final{
  background-image: url("./imagenes/img_footer.png");
  max-width: 100%;
  max-height: 100%;
  padding-top: 2%;
  padding-bottom: 0%;
  color: white;
  display: block;
}

.cont-logo-footer{
  display: flex;
  justify-content: center;
}
.text-privacidad{
  font-family: 'Roboto';
  font-size: 22px;
  color: #fff;
}

.text-privacidad a{
  color: white;
}
.text-logo-foot{
  text-align: center;
}

.text-logo-foot p{
  padding-top: 1%;
  font-size: 12px;
}

.icons-redes-ultimos{
justify-content: center;
padding: 0%;
}
.text-contacto-final{
  text-align: center;
  font-size: 22px;
  font-family: 'Roboto';
}
/*displays, responsives*/
.two-anuncio-responsive{
  display: none;
}
.cont-notes-responsive{
  display: none;
}
.interesante-responsive{
  display: none;
}
.cont-terceranuncio-responsive{
  display: none;
}
.cont-nosotros-responsive{
  display: none;
}
.container-final-responsive{
  display: none;
}
.cont-contacto-responsive{
  display: none;
}

@media screen and (max-width : 575px) { 

  #menuizquierda{
    padding: 14% 115% 0% 17%;
    border-right: 1px solid rgb(187, 181, 181);
    position: relative;
    right: 73%;
    border-left: 1px solid rgb(187, 181, 181);
  }

  #mundomenu{
    padding: 16% 117% 0% 0%;
  }

  #videos{
    padding: 32% 118% 0% 54%;
  }


  #contacto{
    padding: 24% 106% 0% 69%;
  }


  .navbar-light .navbar-toggler {
    display: none;
  }
  .mr-auto{
    padding-left: 15%;
  }
  html{
    padding: 0%;
    margin: 0%;
  }
  .seccion-1{
  padding: 12px 0px 0px 0px;
}
  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
      width: 100%;
      padding-right: 0px;
      padding-left: 0px;
      margin-right: 0px;
      margin-left: 0px;
  }
  #contmenuA{
    background-color: white;
    font-size: 130%;
  }
  .menu{
    font-size:67%;
    color:rgb(7, 7, 7);
    font-size: 60%;
    border-right: 1px solid rgb(187, 181, 181);
  }
#menuizquierdaA{
  border-left: 1px solid rgb(187, 181, 181);
}
  

  .cont-estados{
    position: relative;
    bottom: 4%;
    max-width: 100%;
    max-height: 180px;
    left: 0%;
    margin-inline-end: 0%;
    background-color: rgb(252, 252, 252);
    font-size: 140%;
  }
  .box-estados{
    position: relative;
    left: 5%;
  }

  .carousel-flechas .carousel-control-next-icon {
    position: relative;
    left: 80%;
    background-color: transparent;
    display: none;
}
  .box-estados-responsive{
    font-size: 14px;
    font-family: "Roboto";
    border: 2px solid black;
    max-width: 70px;
    max-height: 25px;
    align-items: center;
    margin: 0px 0px 0px 300px;
    border-radius: 2px;
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0px 0px 0px;
    border: 1px solid rgb(63, 60, 60);
    margin: 10px 10px 9px 0px;
    padding: 15px 0px 0px 0px;
  }

  .seccion-2{
    padding-left: 7%;
    padding-right: 7%;
  }
  .cont-box-locales{
   display: none;
   
  }
  .box-locales-responsive{
    display: block;
  }
  
  .box-locales{
    padding: 4.3% 0px 0px 12px;
  }

  .box-locales-responsive .box-locales{
    /*position: relative;*/
    left: -15%;
  }
  .cont-text-notes{
    font-size: 120%;
    margin-right: 30%;
  }
  .cont-player{
    display: none;
  }

  .cont-player-responsive{
    display: block;
  }
  .cont-player-3-responsive{
  padding-left: 0%;
  }
  .cont-video-backgroud{
    text-align: center;
    color: rgb(209, 9, 109);
    font-size: 50px;
    background-color: black;
    min-height: 300px;
  }
 .cont-image-player-responsive{
   display: flex;
   justify-content: center;
   padding-top: 10%;
 }
 .cont-banders{
   display: none;
 }
 .two-anuncio-responsive{
   margin: 6%;
   display: block;
 }

 .container-seccion-4{
   display:none;
 }
 .cont-notes-responsive{
   display: block;
   margin: 0%;
   padding: 0%;
   position: relative;
   left: 4%;
 }
 .box-estasemana{
   position: relative;
   left: -25%;
 }
 .box-seccion-month-responsive{
   padding-right: 5%;
 }

 .box-estasemana-responsive{
  font-size: 22px;
  color: rgb(231, 20, 161);
  font-family: "Roboto";
  border: 1px solid black;
  max-width: 200px;
  max-height: 25px;
  align-items: center;
  margin: 0px 0px 0px 40%;
  border-radius: 2px;
  display:flex;
  justify-content: center;
  align-items: center;
  padding: 5.2% 0% 0% 0%;
  position:relative;
  right: 21%;
  bottom: 13px;
  z-index: 999;
  background-color: #fff;
 }
 .cont-terceranuncio-responsive{
   margin: 4%;
 }
 .interesante-responsive{
  border-top: 1px solid rgb(97, 93, 93);
  margin-top: 10%;
  display: block;
 }
 .box-interesante{
   position: relative;
   bottom: 15px;
   left: -18%;
   font-size: 98%;
 }

 .cont-anuncio-baninf1{
   margin-top: 3%;
   margin-bottom:3%;
   margin-left: 6%;
   margin-right: 6%;
  }
  .cont-tel-responsivo{
    margin-left: 15%;
    margin-bottom: 5%;
  }
  .icons-redes .nav-link{
    padding: 1%;
    margin: 1%;
  }
  .icons-redes{
    padding-left: 6%;
  }

  .cont-terceranuncio-responsive{
    display: block;
  }

  .cont-interesante{
    display: none;
  }

  .seccion-5{
    display: none;
  }
  
  .text-anuncio-nosotros-responsive p{
    font-family: "Roboto";
    font-size: 22px;
    color: #222222;
  }
  .cont-nosotros-responsive{
    display: block;
    padding-left: 15%;
  }
  .text-sate p{
    text-align: center;
  }
  .text-sub-sate{
    text-align: center;
  }

  .cont-revista{
    background-color: rgb(233, 208, 238);
  }
  .cont-quinto-anuncio{
    padding: 5% 10% 10% 10%;
  }
  .cont-contacto-responsive{
    margin-top: 10%;
    display: block;
    padding-top: 5%;
    border-top: 1px solid rgb(97, 93, 93);
  }

  .container-final-responsive{
    display: block;
    background-image: url("./imagenes/img_footer.png");
    max-width: 100%;
    max-height: 100%;
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 8%;
    color: #fff;
  }
  .cont-final{
    display: none;
  }
  .icons-redes-ultimos{
    width: 117%;
    position: relative;
    right: 5%;
    padding-top: 8%;
  }
  .box-contacto{
    position: relative;
    bottom: 32px;
    left: -15%;
    padding: 4.3% 0% 0% 0%;
  }
  .derechos-reservados-resposive{
    font-size: 80%;
    padding-left: 5%;
    padding-right: 5%;
  }
  .icons-redes-ultimos .nav-link{
    padding: 0% 5% 0% 0%;
    margin: 0% 0% 0% 0%;
  }
  .privacidad-responsive{
    font-size: 90%;
  }
}