@font-face{
    font-family: Athelas;
    src: url("fonts/Athelas.ttc");
  }


#menuresposiveV{
    display: none;
}

.img-sup-video{
    background-image: url("imagenes/imgfondo.png");
}

.cont-img-video{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 10%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.box-text-principal{
    display: flex;
justify-content: center;
padding-top: 1%;
}

.text-video-principal{
    color: rgb(224, 6, 159);
    font-size: 180%;
    font-family: "Athelas";
}
.video-principal{
    padding-bottom: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-videos-secciones{
    width: 100%;
    border-top: 1px solid rgb(224, 6, 159);
    padding-top: 1%;
    padding-bottom: 1%;
}

.box-text-sugerencia{
    display: flex;
    justify-content: center;
    padding-bottom: 2%;
}

.text-sugerencias{
    color: rgb(224, 6, 159);
    font-size: 180%;
    font-family: "Athelas";
}
.video1 .video2 .video3 .video4 .video5{
width: 100%;
padding-left: 5%;
padding-right: 5%;
}


.box-text-list{
display: flex;
justify-content: center;
}

.text-list-videos{
    color: rgb(224, 6, 159);
    font-size: 180%;
    font-family: "Athelas";
}
.container-videos{
    width: 100%;
}
@media screen and (max-width : 575px) {

    .seccion-1{
        border-bottom: 0px solid rgb(97, 93, 93); 
    }

    .box-text-principal {
        display: flex;
        justify-content: center;
        position: relative;
        top: 4%;
    }

    html {
        overflow-x: hidden;
       }

       .text-sugerencias{
        color: rgb(224, 6, 159);
        font-size: 105%;
        text-align: center;
        font-family: "Athelas";
    }

    #menuresposiveV{
        display: block;
    }

    #menuvideos {
        padding: 31% 127% 0% 39%;
        border-right: 1px solid rgb(187, 181, 181);
        border-left: 1px solid rgb(187, 181, 181);
        /* position: relative; */
        /* right: 94%; */
        font-size: 15px;
    }

  #contactov {
    padding: 18% 114% 0% 54%;
    font-size: 16px;
}

#contmenuv {
    background-color: white;
    margin-top: 2%;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-right: 7%;
}

.contenedor-videos {
 
}


}