@font-face{
  font-family: Athelas;
  src: url("fonts/Athelas.ttc");
}

html {
  overflow-x: hidden;
 }

.seccion-1{
    background-image: url("imagenes/imgfondo.png");
    max-width: 100%;
    padding: 0px 0px 0px 0px;
    background-repeat: no-repeat;
    border-bottom: 1px solid rgb(97, 93, 93);
}

#contmenu{
  padding: 0px 0px 0px 0px;
  margin-top: 0px;
  font-size: 158%;
}



  .note-and-anuncio{
    display: flex;
    justify-content: flex-start;
    align-items: center;
   padding-bottom: 10%;
   padding-left: 2%;
   padding-right: 2%;
   position: relative;
   top: 19px;
  }

  .note{
    width: 60%;
  }

  .anuncio1{
    width: 30%;
    padding-bottom: 4%;
    padding-left: 5%;
  }
  .icons-redes {
    margin-top: 2%;
    padding-bottom: 0px;
}
.menu{
  color: #fff;
}
#contmenuN{
  padding-top: 0px;
  margin-bottom: -21px;
  margin-top: 0px;
}
#menunotes{
  display:block;
}
#menuresposivenotes{
  display: none;
}
#menumresponsiveN{
  display: none;
}

.navbar-light .navbar-nav .nav-link {
  color: white;
}

.title{
  font-size: 300%;
  font-family: "Athelas";
  line-height: 1;
}

  .seccion-final{
      background-image: url("./imagenes/img_footer.png");
      max-width: 100%;
      max-height: 100%;
      padding-top: 2%;
      padding-bottom: 0%;
      color: white;
      display: block;
  }

  .col-anuncio-one{
    display: block;
  }

  .seccion-anuncio-inf{
    display: block;
    justify-content: center;
    align-items: center;
    padding: 45px 0px 60px 0px;
    border-bottom: 1px solid rgb(97, 93, 93);
    max-width: 100%;
  }
  .ad-final{
    padding-left: 30%;
    padding-right: 30%;
    display: block;
  }
  
  .ad-final-responsive{
    display: none;
  }

  
  .interesarte{
      margin-bottom: 2%;
      margin-top: 2%;
  }
  .box-interesanteN{
    font-size: 22px;
    color: rgb(231, 20, 161);
    font-family: "Roboto";
    border: 1px solid black;
    max-width: 276px;
    max-height: 25px;
    align-items: center;
    margin: 0px 0px 0px 40%;
    border-radius: 2px;
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0px 0px 0px;
    position:relative;
    left: 1%;
    bottom: 16%;
    z-index: 999;
    background-color: #fff;
  }
  .box-title-interesarte{
    font-size: 22px;
    color: #000;
    font-family: "Roboto";
    border: 1px solid black;
    max-width: 276px;
    max-height: 25px;
    align-items: center;
    margin: 0px 0px 0px 40%;
    border-radius: 2px;
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0px 0px 0px;
    position:relative;
    bottom: 52px;
    z-index: 999;
    background-color: #fff;
  }
.seccion-final-responsive{
  display: none;
}

.img-anuncio{
  padding-left: 10%;
  padding-bottom: 96%;
}
  @media screen and (max-width : 575px) {

    .nav-link {
     color: white;
     font-size: 160%;
    
  }

    html {
      overflow-x: hidden;
     }
    .navbar-light .navbar-toggler {
      display: none;
    }
    
    #modal{
      width: 100%;
    }

    #menunotes{
      display:none;
    }
    #menuresposivenotes{
      display: block;
    }
   .icons-redes{
     display: none;
   }
   #contmenu{
   
      padding: 0px 0px 0px 0px;
      margin-top: 0px;
      font-size: 158%;
  
  }
  #menumresponsiveN{
    display: block;
  }

  container-texts-notes{
    position: relative;
    top: 19px;
  }
  .col-anuncio-one{
    display: none;
  }

  .box-box-notes{
    padding: 10%;
    width: 70%;
  }
  .menu{
    font-size:67%;
    color:rgb(7, 7, 7);
    border-right: 1px solid rgb(187, 181, 181);
  }

  #videos{
    padding: 19% 103% 0% 37%;
  }

  #contacto{
    padding: 15% 111% 0% 35%;
  }
  

  .note{
      width: 100%;
      padding: 5%;
  }
.anuncio1{
  display: none;
}
  .ad-final{
  display: none;
  }
  .ad-final-responsive{
    display: block;
    padding: 5%;
  }
  .Row-interesante{
    display: none;
  }
  .box-interesanteN{
    position: relative;
    bottom:  20px;
    left: -19%;
  }
  .cont-notes{
    padding-left: 11%;
  }
  .seccion-final{
    display: none;
  }
  .seccion-final-responsive{
    display: block;
    background-image: url("./imagenes/img_footer.png");
    max-width: 100%;
    max-height: 100%;
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 8%;
    color: #fff;
  }
  
  }
